// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);"]);
exports.push([module.id, "@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);"]);
// Module
exports.push([module.id, "\n/* You can add global styles to this file, and also import other style files */\nhtml, body {\n  height: 100%;\n}\nbody {\n  margin: 0;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n}\n.afu-attach-pin {\n  padding: 10px;\n}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":";AAAA,8EAAA;AAMA;EAAa,YAAA;AAAb;AACA;EAAO,SAAA;EAAW,iDAAA;AAIlB;AADA;EACE,aAAA;AAIF","file":"styles.scss","sourcesContent":["/* You can add global styles to this file, and also import other style files */\r\n\r\n@import  url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);\r\n@import  url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);\r\n@import  url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);\r\n\r\nhtml, body { height: 100%; }\r\nbody { margin: 0; font-family: Roboto, \"Helvetica Neue\", sans-serif; }\r\n\r\n\r\n.afu-attach-pin{\r\n  padding: 10px;\r\n}\r\n\r\n.label-info {\r\n\r\n}\r\n"]}]);
// Exports
module.exports = exports;
